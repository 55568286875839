import { createApp } from 'vue'

import "./style/common.less"
import App from './App.vue'

import bootstrap from "@/bootstrap";

const app = createApp(App);
bootstrap(app);

app.mount('#app')
