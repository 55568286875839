import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7058cd2c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "list-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_button = _resolveComponent("a-button");
  const _component_a_space = _resolveComponent("a-space");
  const _component_a_row = _resolveComponent("a-row");
  const _component_custom_list_item = _resolveComponent("custom-list-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_row, null, {
    default: _withCtx(() => [_createVNode(_component_a_space, null, {
      default: _withCtx(() => [_createVNode(_component_a_button, {
        type: "primary",
        onClick: _ctx.addItem
      }, {
        default: _withCtx(() => [_createTextVNode("添加")]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerValue, (itemValue, index) => {
    return _openBlock(), _createBlock(_component_custom_list_item, {
      key: itemValue.key,
      "parent-field": _ctx.fieldConfig.model,
      index: index,
      "field-list": _ctx.innerFieldList,
      value: itemValue,
      onRemove: _ctx.removeItem
    }, null, 8, ["parent-field", "index", "field-list", "value", "onRemove"]);
  }), 128))]);
}