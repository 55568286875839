import {
    Alert,
    ConfigProvider,
    Input,
    Tabs,
    Spin,
    Card,
    Button,
    Form,
    Select,
    DatePicker,
    Radio,
    Space,
    Drawer,
    Modal,
    Tag,
    Row,
    Col,
    Layout,
    Menu,
    Divider,
    Breadcrumb,
    InputNumber,
    Empty,
    Popover,
    Pagination,
    Upload,
    Checkbox,
    Avatar,
    Dropdown
} from "ant-design-vue";
import 'ant-design-vue/dist/antd.css';

export function registerAntComponent(instance){
    let useComponent = [Alert,ConfigProvider,Input,Tabs,Spin,Card,Button,Form,Select,DatePicker,Radio,Space,Drawer,Modal,Tag,Row,Col,Layout,Menu,Divider,Breadcrumb,InputNumber,Empty,Popover,Pagination,Upload,Checkbox,Avatar,Dropdown];
    useComponent.forEach((item)=>{
        instance.use(item);
    });
}
