import {defineComponent,h} from "vue"
import svgVip from "./svgVip"


let svgIcon = {
    svgVip:svgVip
}

const Icon = defineComponent({
    props:{
        icon:{
            type:String,
            default:""
        },
        size:{
            type:String,
            default:"16px"
        }
    },
    setup(props){
        return ()=>{
            return props.icon?h(svgIcon[props.icon],{size:props.size}):h("")
        }
    }
});

export default Icon


export const iconPlugin = {
    install(app){
        app.component("custom-icon",Icon)
    }
}
