import Axios from "axios";
const devUrl = "https://www.lanmaodianwan.top";
// const devUrl = "http://localhost:3000";
const axiosInstance = Axios.create({
    baseURL:devUrl
});

axiosInstance.interceptors.request.use(function(config){
    let accountId = localStorage.getItem("accountId");
    if(accountId){
        let assignData = {accountId:Number(accountId)};
        if(config.method==='post'){
            if(!config.data)config.data = {};
            Object.assign(config.data,assignData);
        }else{
            if(!config.data)config.params = {};
            Object.assign(config.params,assignData);
        }
    }
    return config
},function(err){
    return Promise.reject(err);
})

axiosInstance.interceptors.response.use(function(response){
    return response.data;
},function(error){
    return Promise.reject(error);
})

export default function customRequest(config){
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    axiosInstance.cancelSource = source;
    return axiosInstance({
        method:"post",
        cancelToken:source.token,
        ...config
    })
};
