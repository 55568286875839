import CustomUpload from "@/component/custom/custom-upload.vue";
import CustomSyncSelect from "@/component/custom/custom-sync-select.vue";
import CustomDatePick from "@/component/custom/custom-date-picker.vue";
import CustomList from "@/component/custom/custom-list.vue"

export default {
    install(app){
        let component = [CustomUpload,CustomSyncSelect,CustomDatePick,CustomList];
        component.forEach((com)=>{
            app.component(com.name,com);
        })
    }
}
