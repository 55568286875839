import { PlusOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  name: "custom-upload",
  components: {
    LoadingOutlined,
    PlusOutlined,
    UploadOutlined
  },
  emits: ["update:value", "change"],
  props: {
    limitSize: {
      type: Number,
      default: 2
    },
    limit: {
      type: Number,
      default: 1
    },
    limitType: {
      type: Array,
      default: () => []
    },
    uploadType: {
      type: String,
      default: "text"
    },
    value: {
      type: [Array, null, String]
    }
  },
  setup(props, {
    emit
  }) {
    const fileList = ref(props.value ? [...props.value] : []);
    const change = () => {
      let arr = fileList.value.filter(file => file.status === "done").map(file => ({
        type: "file",
        name: file.name,
        status: file.status,
        uid: file.uid,
        url: file.url
      }));
      emit("update:value", arr.length ? arr : "");
      emit("change", arr.length ? arr : "");
    };
    const handleChange = info => {
      if (info.file.status === 'uploading') return;
      if (info.file.status === 'done') {
        let response = info.file.response;
        if (response.state === 1) {
          info.file.url = response.data.url;
          change();
        } else {
          info.file.status = "error";
        }
      }
      if (info.file.status === 'error') {
        message.error('upload error');
      }
    };
    const beforeUpload = file => {
      const isAllowType = props.limitType.includes(file.type);
      if (!isAllowType) {
        message.error('类型格式不符合！目前支持' + props.limitType.join(",") + "类型的文件");
        return false;
      }
      const isAllowSize = file.size / 1024 / 1024 < props.limitSize;
      if (!isAllowSize) {
        message.error('图片大小不能超过2M');
        return false;
      }
      const isAllowLength = fileList.value.length + 1 <= props.limit;
      if (!isAllowLength) {
        message.error('最大上传个数' + props.limit + "个");
        return false;
      }
      return isAllowType && isAllowSize && isAllowLength;
    };
    const remove = it => {
      let index = fileList.value.findIndex(item => item.uid === it.uid);
      fileList.value.splice(index, 1);
      change();
      return true;
    };
    watch(() => props.value, nv => {
      if (nv) {
        fileList.value = nv;
      } else {
        fileList.value = [];
      }
    });
    return {
      fileList,
      handleChange,
      beforeUpload,
      remove
    };
  }
});