import "core-js/modules/es.array.push.js";
import { computed, defineComponent, ref, watch } from "vue";
import CustomListItem from "@/component/custom/custom-list-item.vue";
import { Form } from "ant-design-vue";
export default defineComponent({
  name: "custom-list",
  components: {
    CustomListItem
  },
  props: {
    value: {
      type: Array
    },
    fieldConfig: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, {
    emit
  }) {
    const formItemContext = Form.useInjectFormItemContext();
    const innerFieldList = computed(() => props.fieldConfig.innerFields || []);
    const innerValue = ref([]);
    const addItem = () => {
      innerValue.value.push({
        key: Date.now()
      });
    };
    const changeItemValue = () => {
      // emit("change",innerValue.value);
    };
    const removeItem = v => {
      let index = innerValue.value.findIndex(it => it.key === v.key);
      innerValue.value.splice(index, 1);
    };
    const targetChange = val => {
      innerValue.value = val;
      emit("update:value", val);
      formItemContext.onFieldChange();
    };
    watch(() => props.value, nv => {
      if (!nv) targetChange([]);else targetChange(nv);
    }, {
      deep: true,
      immediate: true
    });
    return {
      innerFieldList,
      innerValue,
      addItem,
      removeItem,
      changeItemValue
    };
  }
});