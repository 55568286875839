import {createRouter,createWebHashHistory} from "vue-router";

const router = createRouter({
    history:createWebHashHistory("/"),
    routes:[
        {
            path:"/",
            name:"index",
            component:()=>import("@/pages/index.vue"),
            redirect:"/metering-time",
            children:[
                {
                    path:"/metering-time",
                    name:"meteringTime",
                    component:()=>import("@/pages/MeteringTime/index.vue")
                },
                {
                    path:"/room-manage",
                    name:"roomManage",
                    component:()=>import("@/pages/RoomManage/index.vue")
                },
                {
                    path:"/game-manage",
                    name:"gameManage",
                    component:()=>import("@/pages/GameManage/index.vue")
                },
                {
                    path:"/question-manage",
                    name:"questionManage",
                    component:()=>import("@/pages/QuestionManage/index.vue")
                },
                {
                    path:"/subscribe-manage",
                    name:"subscribeManage",
                    component:()=>import("@/pages/SubscribeManage/index.vue")
                },
                {
                    path:"/miniApp-set",
                    name:"miniAppSet",
                    component:()=>import("@/pages/MiniAppSet/index.vue")
                },
                {
                    path:"/order-list",
                    name:"orderList",
                    component:()=>import("@/pages/OrderList/index.vue")
                },
                {
                    path:"/user-manage",
                    name:"userManage",
                    component:()=>import("@/pages/UserManage/index.vue")
                }
            ]
        },
        {
            path:"/login",
            name:"login",
            component:()=>import("@/pages/Login/login.vue")
        },

    ]
})
router.beforeEach((to,from,next)=>{
    let isLogin = localStorage.getItem("accountId");
    if(to.path==="/login"){
        return next(true);
    }
    if(!isLogin){
        next({path:"/login"});
    }else{
        next();
    }
})
export default router;
