import { defineComponent, ref, computed } from 'vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
export default defineComponent({
  setup() {
    let locale = ref(zhCN.locale);
    return {
      locale,
      zhCN
    };
  }
});