import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_select_option = _resolveComponent("a-select-option");
  const _component_a_select = _resolveComponent("a-select");
  return _openBlock(), _createBlock(_component_a_select, {
    value: _ctx.currentValue,
    "onUpdate:value": _cache[0] || (_cache[0] = $event => _ctx.currentValue = $event),
    mode: _ctx.mode,
    "max-tag-count": _ctx.maxTagCount,
    style: {
      "width": "100%"
    },
    placeholder: _ctx.placeholder,
    onChange: _ctx.handleChange
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSource, item => {
      return _openBlock(), _createBlock(_component_a_select_option, {
        key: item[_ctx.valueName],
        value: item[_ctx.valueName],
        label: item[_ctx.labelName]
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item[_ctx.labelName]), 1)]),
        _: 2
      }, 1032, ["value", "label"]);
    }), 128))]),
    _: 1
  }, 8, ["value", "mode", "max-tag-count", "placeholder", "onChange"]);
}