import { defineComponent, onMounted, ref, computed } from "vue";
import customRequest from "@/utils/axios.config";
import _ from "lodash";
export default defineComponent({
  name: "custom-sync-select",
  props: {
    placeholder: {
      type: String,
      default: "请选择"
    },
    labelName: {
      type: String,
      default: "label"
    },
    valueName: {
      type: String,
      default: "value"
    },
    multiple: {
      type: Boolean,
      default: false
    },
    getUrl: {
      type: String,
      default: ""
    },
    value: undefined,
    disabled: {
      type: Boolean
    },
    maxTagCount: {
      type: Number,
      default: 3
    }
  },
  setup(props, {
    emit
  }) {
    const innerValue = props.multiple ? [] : null;
    const loading = ref(false);
    const currentValue = ref(props.value || innerValue);
    const dataSource = ref([]);
    const mode = computed(() => props.multiple ? 'multiple' : "");
    let remoteGetData = (param = {}) => {
      loading.value = false;
      customRequest({
        url: props.getUrl,
        data: {
          ...param
        }
      }).then(({
        state,
        data
      }) => {
        if (state === 1) {
          dataSource.value = data;
        } else {}
      }).catch(() => {}).finally(() => {
        loading.value = true;
      });
    };
    const handleChange = function (value) {
      emit("update:value", value);
      emit("change", value);
    };
    const _remoteGetData = _.debounce(remoteGetData, 500);
    onMounted(() => {
      remoteGetData({});
    });
    return {
      mode,
      loading,
      currentValue,
      dataSource,
      remoteGetData: _remoteGetData,
      handleChange
    };
  }
});