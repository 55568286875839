import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-27e59ac2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "item-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_form_item = _resolveComponent("a-form-item");
  const _component_a_col = _resolveComponent("a-col");
  const _component_a_row = _resolveComponent("a-row");
  const _component_a_button = _resolveComponent("a-button");
  const _component_a_space = _resolveComponent("a-space");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_row, {
    wrap: true,
    gutter: 12
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldList, field => {
      return _openBlock(), _createBlock(_component_a_col, {
        span: 12,
        key: _ctx.fieldName(field.model)
      }, {
        default: _withCtx(() => [_createVNode(_component_a_form_item, {
          label: field.label,
          name: _ctx.fieldName(field.model),
          rules: field.rules
        }, {
          default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(field.type), _mergeProps({
            value: _ctx.value[field.model],
            "onUpdate:value": $event => _ctx.value[field.model] = $event
          }, field.formViewProps, {
            fieldConfig: field
          }), _createSlots({
            _: 2
          }, [_renderList(field.formViewSlot || {}, (view, name) => {
            return {
              name: name,
              fn: _withCtx(() => [_createTextVNode(_toDisplayString(view.content), 1)])
            };
          })]), 1040, ["value", "onUpdate:value", "fieldConfig"]))]),
          _: 2
        }, 1032, ["label", "name", "rules"])]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }), _createVNode(_component_a_space, null, {
    default: _withCtx(() => [_createVNode(_component_a_button, {
      type: "danger",
      onClick: _ctx.remove
    }, {
      default: _withCtx(() => [_createTextVNode("删除")]),
      _: 1
    }, 8, ["onClick"])]),
    _: 1
  })]);
}