import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_date_picker = _resolveComponent("a-date-picker");
  return _openBlock(), _createBlock(_component_a_date_picker, {
    value: _ctx.innerValue,
    "onUpdate:value": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event),
    format: _ctx.format,
    picker: _ctx.picker,
    showTime: _ctx.showTime,
    onChange: _ctx.change
  }, null, 8, ["value", "format", "picker", "showTime", "onChange"]);
}