import { defineComponent } from "vue";
export default defineComponent({
  props: {
    parentField: {
      type: String
    },
    index: {
      type: Number
    },
    fieldList: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  emits: ["remove"],
  setup(props, {
    emit
  }) {
    const remove = () => {
      emit("remove", props.value);
    };
    const fieldName = field => {
      return [props.parentField, props.index, field];
    };
    return {
      fieldName,
      remove
    };
  }
});