import {registerAntComponent} from "./utils/ant.config";
import useTable from "@/utils/vxeTable.config";
import router from "@/router/router";
import {iconPlugin} from "@/component/icon/icon"
import CustomComponent from "@/utils/customComponent";

const isMobile = ()=>{
    let userAgent = navigator.userAgent;
    let agents = ["Android","iPhone","iPad","iPod"];
    let getArr = agents.filter(i=>userAgent.includes(i));
    return !!getArr.length;
}


export default function(instance){
    /**注册ant-design-vue组件*/
    registerAntComponent(instance);
    useTable(instance);
    instance.use(router);
    instance.use(iconPlugin);
    instance.use(CustomComponent);
    instance.config.globalProperties.$isMobile = isMobile();
}
