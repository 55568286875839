import XEUtils from "xe-utils";
import {
    VXETable,
    Column,
    Table,
    Tooltip
} from "vxe-table";

import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
VXETable.setup({
    i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args)
})

function useTable (app) {
    // 表格功能
    app.use(Column);
    app.use(Table);
    app.use(Tooltip);
}
export default useTable;

