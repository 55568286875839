import { defineComponent, ref } from "vue";
import moment from "moment";
export default defineComponent({
  name: "custom-date-picker",
  props: {
    format: {
      type: String,
      default: "YYYY-MM-DD"
    },
    picker: {
      type: String,
      default: "date"
    },
    showTime: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String, null]
    }
  },
  setup(prop, {
    emit
  }) {
    let value = prop.value ? moment(Number(prop.value)) : "";
    const innerValue = ref(value);
    const change = date => {
      let timeStamp = date.valueOf();
      emit("update:value", timeStamp);
      emit("update:value", timeStamp);
    };
    return {
      innerValue,
      change
    };
  }
});